import React from 'react';
import * as R from 'ramda';

export type ButtonGroupValue = string | undefined;

type Props = {
  children: React.ReactNode;
  active?: ButtonGroupValue;
  onChange: (value?: ButtonGroupValue) => void;
  size?: string;
  type?: string;
};

export const ButtonGroup = ({ children, active, onChange, ...buttonProps }: Props) => {
  const buttons = React.Children.map(children, (child) => {
    // @ts-expect-error
    const hasWrapper = !(child.props.value !== undefined || typeof child.props.children === 'string');

    // @ts-expect-error
    const button = hasWrapper ? child.props.children : child;
    const isActive = R.equals((button.props.value || ''), active);

    const props = {
      ...buttonProps,
      onClick: button.onClick || (() => onChange(button.props.value || '')),
      type: isActive ? 'primary' : buttonProps.type,
      active: isActive,
    };

    // @ts-expect-error
    return React.cloneElement(child, hasWrapper
      ? {
        // @ts-expect-error
        ...child.props,
        // @ts-expect-error
        children: React.cloneElement(child.props.children, props),
      }
      : props);
  });

  return <div className="ButtonGroup">{buttons}</div>;
};

export default ButtonGroup;
