import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import ProgressBar from '../progress-bar';
import Icon from '../icon';

import { EFileTypes, fileTypesStyles } from '../../definitions';
import { Attachment, FileInProgress } from '@common/types/objects';
import { combineClassNames } from '@common/utils/combineClassNames';

type FilePreviewOwnProps = {
  file: Attachment & FileInProgress;
  onRemove: () => void;
};

const FilePreview = ({ file, onRemove }: FilePreviewOwnProps) => {
  if (!file) return null;

  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (file.progress) {
      file.progress((value) => setProgress(value));
    }
    if (file.promise) {
      file.promise.catch((err) => {
        setError(err);
      });
    }
    return () => file.progress && file.progress(undefined);
  }, []);

  const [type] = (file.file_type || file.type || '').split('/');
  const fileType = useMemo(() => {
    switch (type) {
      case 'pdf':
        return {
          icon: 'document',
          image: fileTypesStyles[EFileTypes.PDF],
          label: t('common:file_preview_label_pdf'),
        };
      case 'video':
        return {
          icon: 'local_movies',
          image: fileTypesStyles[EFileTypes.VIDEO],
          label: t('common:file_preview_label_video'),
        };
      case 'image':
        return {
          icon: 'image',
          image: fileTypesStyles[EFileTypes.IMAGE],
          label: t('common:file_preview_label_image'),
        };
      default:
        return {
          icon: 'document',
          image: fileTypesStyles[EFileTypes.UNKNOWN],
          label: t('common:file_preview_label_file'),
        };
    }
  }, [t, file]);

  const statusText = useMemo(() => {
    if (error) return progress >= 100 ? t('common:file_preview_error_processing') : t('common:file_preview_error_uploading');
    if (progress === 100 && file.processing) return `${fileType.label} ${t('common:file_preview_processing')}...`;
    if (file.completed) return `${fileType.label} ${t('common:file_preview_uploaded')}`;

    return `${fileType.label} ${t('common:file_preview_uploading')}...`;
  }, [fileType, file, progress, t, error]);

  const className = useMemo(() => {
    return combineClassNames('FilePreview FilePreview--file', {
      'FilePreview--processing': file.processing,
      'FilePreview--uploading': progress < 100,
    });
  }, [file, progress]);

  return (
    <div className={className}>
      <div className="FilePreview__Container">
        <img src={fileType.image} alt="FilePreview" />
        <div className="FilePreview__Info">
          <div className="FilePreview__Info__Name">{file.file_name}</div>
          <small>{statusText}</small>
        </div>
        <div className="FilePreview__Remove" onClick={onRemove}>
          <Icon type="close" />
        </div>
      </div>
      {!file.completed && (
        <ProgressBar
          status={file.completed ? 100 : progress}
          error={error}
          processing={progress === 100 && file.processing}
        />
      )}
    </div>
  );
};

export default FilePreview;
