import * as React from 'react';

const SectionHeaderComponent = ({ title, size }) => {
  const className = ['SectionHeader'];
  if (size) className.push(`SectionHeader--size-${size}`);

  return (
    <div className={className.join(' ')}>
      {title}
    </div>
  );
};

export default SectionHeaderComponent;
